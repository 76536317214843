.input {
  background-color: transparent;
  border: 1px solid transparent;
}
.input.red {
  color: red;
}
.input.green {
  color: green;
}

.input:hover {
  background-color: #d3d3d3;
  border: 1px solid black;
  cursor: pointer;
}